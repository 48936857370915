


  <script type="text/javascript">
  
    $(window).on('load',function(){
        $('#myModal').modal('show');
    });

    $(document).ready(function() {
      $('#myModal').modal('show');
    });
  </script>

<div>
    <img src="/assets/image/Biblios 4.png"  style="
     margin: 10px auto 20px;
     margin-bottom: 5px;
     display: block;
     height: 200px;">
</div>
  
    
  <div class="container">
    <!-- FILTRO -->
    <div>
      
      <form novalidate [formGroup]="form">
        <div class="row" style="margin-bottom:10px;">
          <div class="col">
            <input class="form-control my-0 py-1 form-control-xs" formControlName="search" type="text"
              placeholder="Search...">
          </div>
        </div>
        <div class="row" style="margin-bottom:10px;">
          <div class="col">
            <input type="text" formControlName="author" class="form-control form-control-xs" placeholder="Author" />
          </div>
          <div class="col">
            <input type="text" formControlName="agefrom" class="form-control form-control-xs" placeholder="Year from" />
          </div>
          <div class="col">
            <input type="text" formControlName="ageto" class="form-control form-control-xs" placeholder="Year to" />
          </div>
          <div class="col">
            <select [(ngModel)]="cat1" class="form-control form-control-xs" style="padding:0;" formControlName="cat1" placeholder="Year to"
            (change)="modo($event.target.value)">
              <option [ngValue]="null">Category</option>
              <option [ngValue]="level" *ngFor="let level of levels">{{level}}</option>
            </select>
          </div>
  
          <div class="col">
            <select *ngIf="addCatCount" [(ngModel)]="cat2" class="form-control form-control-xs" style="padding:0;" formControlName="cat2">
              <option [ngValue]="null" > Filter with other Category </option>
              <option [ngValue]="level" *ngFor="let level of addCat">{{level}}</option>
            </select>
          </div>
        </div>
        <div class="row justify-content-md-center" style="margin-bottom:20px;margin-top:20px;">
            <button class="btn btn-primary form-control-xs-button" (click)="filterArticles('filterril',0)"
            style="margin-right: 20px;width: 100px;">
              Search
              <i class="fas fa-search text-grey" aria-hidden="true"></i> 
            </button>
            <button class="btn btn-primary form-control-xs-button" (click)="form.reset();resetSearch()"
            style="margin-right: 20px;width: 100px;">
              Reset 
            </button>
        </div>
        
        <div style="color:dimgray; font-size: 12px;">
          Last DB Update: 07/02/2025
        </div>
        
      </form>
    </div>
    <!------------------------------------------------------------------------------------------------------->
   <!--
    <div>
      <table>
    
        <tbody>
          <tr>
            
            
            <table class="table table-custom table-bordered">
              <tr class="table-active" style="font-size:22px;">
                <td colspan="4"><b>{{ArticleEv.titolo}}</b></td>
              </tr>
              <tr class="table-light" style="font-size:17px;">
                <i>
                  <td ><b>{{ArticleEv.rilevanza}}</b></td>
                  <td>
                    <a class="nav-link" href="#" (click)="form.reset();filterArticles('onlyAuth',ArticleEv.autore_princ); "
                      style="color:#4694c2 ">
                      <i class="fas fa-user-edit"></i>&nbsp;{{ArticleEv.autore_princ}}
                    </a>
                  </td> 
                  <td>
                    <div style="display: block; padding:.5rem 1rem;">
                      <i class="fas fa-users"></i>&nbsp;{{ArticleEv.autore_sec}}
                    </div>
                    
                  </td>
                </i>
              </tr>
              <div class="card">
                <div class="card-header" style="padding: .10rem 1.25rem; font-size:15px;">
                  <div class="row" >
                    <div class="col">
                        <div *ngIf="(ArticleEv.doi != '-' && ArticleEv.doi)">
                          <a target="_black" href="https://doi.org/{{ArticleEv.doi}}">
                            <i class="fas fa-file-alt"></i>
                            {{ArticleEv.doi}} 
                          </a>&nbsp;
                          
                          <a [routerLink]="" (click)="open(mymodal,ArticleEv)">
                            <i class="far fa-question-circle"></i>
                            For more information contact us!
                          </a>
                        </div>
                        <div *ngIf="(ArticleEv.doi == '-' || !ArticleEv.doi)">
                          <a [routerLink]="" (click)="open(mymodal,ArticleEv)">
                            <i class="far fa-question-circle"></i>
                            For more information contact us!
                          </a>
                        </div>
                        
                     
                    </div>
                    <div class="col" >
                      <a class="card-link" data-toggle="collapse" href="#collapse{{ArticleEv._id}}" (click)="incVisite(ArticleEv._id)" style="float: right;">
                        <i class="fas fa-info-circle"></i>&nbsp;Details
                      </a>
                    </div>
                  </div>
                </div>
                <div id="collapse{{ArticleEv._id}}" class="collapse ">
                  <div class="card-body" style="font-size:17px;">
                    <i class="fas fa-calendar-alt"></i>&nbsp; <b>Year:</b> {{ArticleEv.anno_art}}<br>
                    <i class="far fa-address-book"></i>&nbsp; <b>Reference:</b> {{ArticleEv.riferimento}} <br>
                    <i class="fas fa-bookmark"></i>&nbsp; <b>Published as:</b> {{ArticleEv.tipologia_lav|titlecase}} <br>
                    <i class="fas fa-book-medical"></i>&nbsp; <b>Category:</b> {{ArticleEv.cat1}}&nbsp;-&nbsp;{{ArticleEv.cat2}}&nbsp;-&nbsp;{{ArticleEv.cat3}}&nbsp;-&nbsp;{{ArticleEv.cat4}}<br>
                    <i class="fas fa-tag"></i>&nbsp; <b>Keywords:</b> {{ArticleEv.tag|titlecase}}
                  </div>
                </div>
              </div>
            </table>
  
          </tr>
        </tbody>
      </table>
    </div>
  -->
    <!------------------------------------------------------------------------>
   
    <div class="row justify-content-md-center"  *ngIf="countIf">
      {{foundItem}}
    </div>
    <div class="row " style="margin-bottom:10px;margin-top:20px;" *ngIf="countIf" >
      <div class="col" style="text-align: right;">
        <button class="btn btn-link form-control-xs-button" (click)="filterArticles('filterdata',0)"
        style="margin-right: 20px;width: 150px;" [disabled]="!sortDate">
          <i class="far fa-calendar-alt"></i>&nbsp;Sort by date&nbsp;<i class="far fa-caret-square-down"></i>
        </button>
        <button class="btn btn-link form-control-xs-button" (click)="filterArticles('filteraut',0)"
        style="margin-right: 20px;width: 150px;" [disabled]="!sortAut">
          <i class="fas fa-user-edit"></i>&nbsp;Sort by author&nbsp;<i class="far fa-caret-square-down"></i>
        </button>
      </div>
    </div>
  

    <!------------------------------------------------------------------------>
    <div class="table-wrapper-scroll-y my-custom-scrollbar" *ngIf="countIf">
        <table>
    
          <tbody>
            <div *ngFor="let article of Articles; let i = index">
              
              <table class="table table-custom table-bordered">
                <tr class="table-active" style="font-size:22px;">
                  <td colspan="4"><b>{{article.titolo}}</b></td>
                </tr>
                <tr class="table-light" style="font-size:17px;">
                  <i>
                    <td>
                      <a class="nav-link" href="#" (click)="form.reset();filterArticles('onlyAuth',article.autore_princ); "
                        style="color:#4694c2 ">
                        <i class="fas fa-user-edit"></i>&nbsp;{{article.autore_princ}}
                      </a>
                    </td> 
                    <td>
                      <div style="display: block; padding:.5rem 1rem;">
                        <i class="fas fa-users"></i>&nbsp;{{article.autore_sec}}
                      </div>
                      
                    </td>
                  </i>
                </tr>
                <div class="card">
                  <div class="card-header" style="padding: .10rem 1.25rem; font-size:15px;">
                    <div class="row" >
                      <div class="col">
                        <!--<div data-toggle="tooltip" data-placement="top" title="For more information contact us info@hedsrl.it">-->

                          <div *ngIf="(article.doi != '-' && article.doi)">
                            <a class="card-link" data-toggle="collapse" href="#collapse{{article._id}}" (click)="incVisite(article._id)">
                              <i class="fas fa-info-circle"></i>&nbsp;Details
                            </a>&nbsp;&nbsp;

                            <a target="_black" href="https://doi.org/{{article.doi}}">
                              <i class="fas fa-file-alt"></i>
                              {{article.doi}} 
                            </a>&nbsp;
                            
                            <a [routerLink]="" (click)="open(mymodal,article)">
                              <i class="far fa-question-circle"></i>
                              For more information contact us!
                            </a>
                          </div>
                          <div *ngIf="(article.doi == '-' || !article.doi)">
                            <a class="card-link" data-toggle="collapse" href="#collapse{{article._id}}" (click)="incVisite(article._id)">
                              <i class="fas fa-info-circle"></i>&nbsp;Details
                            </a>&nbsp;&nbsp;

                            <a [routerLink]="" (click)="open(mymodal,article)">
                              <i class="far fa-question-circle"></i>
                              For more information contact us!
                            </a>
                          </div>
                      </div>
                      <!--
                      <div class="col" >
                        <a class="card-link" data-toggle="collapse" href="#collapse{{article._id}}" (click)="incVisite(article._id)" style="float: right;">
                          <i class="fas fa-info-circle"></i>&nbsp;Details
                        </a>
                      </div>-->
                    </div>
                  </div>
                  <div id="collapse{{article._id}}" class="collapse ">
                    <div class="card-body" style="font-size:17px;">
                      <i class="fas fa-calendar-alt"></i>&nbsp; <b>Year:</b> {{article.anno_art}}<br>
                      <i class="far fa-address-book"></i>&nbsp; <b>Reference:</b> {{article.riferimento}} <br>
                      <i class="fas fa-bookmark"></i>&nbsp; <b>Published as:</b> {{article.tipologia_lav|titlecase}} <br>
                      <i class="fas fa-book-medical"></i>&nbsp; <b>Category:</b> {{article.cat1}}&nbsp;-&nbsp;{{article.cat2}}&nbsp;-&nbsp;{{article.cat3}}&nbsp;-&nbsp;{{article.cat4}}<br>
                      <i class="fas fa-tag"></i>&nbsp; <b>Keywords:</b> {{article.tag|titlecase}}
                    </div>
                  </div>
                </div>
              </table>
            </div>
          </tbody>
    </table>
</div>
-
    <div style="height: 245px;" *ngIf="!countIf">

    </div>
    

  </div>
  


  <ng-template #mymodal let-modal>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Contact Us</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  
    <form (submit)="sendMail()" #regForm="ngForm">
        <div class="modal-body">
  
                <div class="form-row">
                    <div class="form-group col">
                        <h5>Your Address</h5>
                        <input id="email" type="text" class="form-control" name="email" [(ngModel)]="email"
                        placeholder="Enter your e-Mail address"
                        #mail="ngModel" 
                    [ngClass]="{ 
                      'is-invalid': mail.errors?.pattern || (mail.errors?.required && mail.touched), 
                      'is-valid': mail.valid }"
                      required pattern="[a-z0-9._%+-]+@[a-z0-9-.]+\.[a-z]{2,4}$" >
                    </div>                  
                </div>
                <div class="form-row">
                  <div class="form-group col">
                    <div *ngIf="mail.errors?.pattern" class="text-danger">e-Mail not valid!</div>
                    <div *ngIf="mail.errors?.required && mail.touched" class="text-danger">e-Mail required!</div>
                  </div>
                </div> 

                <div class="form-row">
                  <div class="form-group col">
                    <h5>Article request</h5>
                    <input id="titoloart" type="text" class="form-control" name="titoloart" [(ngModel)]="modalTitoloArt" disabled>
                    <input id="idart" type="hidden" class="form-control" name="idart" [(ngModel)]="modalIdArt">
                  </div>
                </div>
  
                <div class="form-row">
                  <div class="form-group col">
                      <h5>Your Message</h5>
                      <textarea id="email" type="text" class="form-control" name="testoMail" [(ngModel)]="testoMail"
                      placeholder="Enter your feedback, question or request" rows="8" cols="50" 
                      #name="ngModel" required [ngClass]="{ 'is-invalid': !name.valid && name.touched, 'is-valid': name.valid && name.touched }"> 
                      </textarea>
                  </div>
                </div> 
                <div class="form-row">
                  <div class="form-group col">
                    <div *ngIf="!name.valid && name.touched" class="text-danger">Text required!!</div>
                  </div>
                </div>
  
                <div class="form-row">
                    <div class="form-group col">
                        <div *ngIf="isShown">
                            <p style="color: forestgreen;"><b>email successfully sent!</b></p>
                        </div>   
                    </div>
                </div>             
        </div>
    
        <div class="modal-footer" style="justify-content: center;">
            <div class="row buttons-bottom-group" >   
                <div class="col text-center" *ngIf="!isShown">
                    <button type="submit" class="btn btn-success button-bottom"[disabled]="!regForm.form.valid">
                        <i class="fa fa-envelope size:3x text-grey" aria-hidden="true"></i>
                    </button>
                </div>    
            </div>
        </div>
    </form> 
  
  </ng-template>

  <ng-template #homemodal let-modal>
 
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div style="text-align:center; vertical-align: middle;">
      <img src="/assets/image/Biblios 4.png"  style="
      
      margin-left: auto;
      margin-right: auto;
      height: 120px;">
    </div>
  
    <form (submit)="sendMail()" #regForm="ngForm">
        <div class="modal-body" >
          <div class="form-row" style="margin-bottom: 20px;text-align:center">
            <p>
              Welcome to the new search engine developed by <b><i>H&D S.r.l.</i></b> entirely dedicated to the world of
              oxidative stress.

              For more information, fill in the following field with your email.
            </p>
            
          </div>

          <div class="form-row" style="margin-bottom: 15px;">
            <div class="col" style="text-align: right;">
              <input #saveUserNameCheckBox 
                    id="saveUserNameCheckBox"
                    type="checkbox" 
                    name="checkemail" 
                    [checked]="saveUsername"
                    (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked)">
            </div>

            <div class="col-11" my-auto>
              <div style="font-size: 13px;">
                <label for="vehicle1" style="margin-top: 4.5px;">Yes, I would like to receive scientific and marketing information from H&D
                  S.r.l. and its affiliates relating to their products and services.
                </label>
              </div>
            </div>
          </div>
            
          <div class="form-row">
              <div class="form-group col">
                  <h5>e-Mail</h5>
                  <input id="email" type="text" class="form-control" name="email" [(ngModel)]="email"
                  placeholder="Enter your e-Mail address"
                  #mail="ngModel" 
              [ngClass]="{ 
                'is-invalid': mail.errors?.pattern || (mail.errors?.required && mail.touched), 
                'is-valid': mail.valid }"
                required pattern="[a-z0-9._%+-]+@[a-z0-9-.]+\.[a-z]{2,4}$" >
              </div>                  
          </div>
          <div class="form-row">
            <div class="form-group col">
              <div *ngIf="mail.errors?.pattern" class="text-danger">e-Mail not valid!</div>
              <div *ngIf="mail.errors?.required && mail.touched" class="text-danger">e-Mail required!</div>
            </div>
          </div>  
          <div class="form-row">
            <div class="form-group col">
                <div *ngIf="isShown">
                    <p style="color: forestgreen;"><b>email successfully sent!</b></p>
                </div>   
            </div>
          </div>         
        </div>
        
        <div class="modal-footer" style="justify-content: center;">
            <div class="row buttons-bottom-group" *ngIf="!isShown">   
                <div class="col text-center" ><!--*ngIf="regForm.form.valid && saveUsername"-->
                    <button type="submit" class="btn btn-success button-bottom" [disabled]="(!regForm.form.valid || !saveUsername )">
                        <i class="fa fa-envelope size:3x text-grey" aria-hidden="true"></i>
                    </button>
                </div>    
            </div>
        </div>
    </form> 
     
  
  </ng-template>

  <app-footer class="mt-auto"></app-footer>

  
  