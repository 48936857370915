<!--
<footer class="footer text-xs-center form-fooder" style="background-color: #d6d7d9;">
	<div style="align-items: center;">
		<p class="text-muted" style="font-size: 18px;">
			<small>&copy; 2021 by H&D S.r.l.</small> &nbsp;|
		</p>
		<p class="text-muted" style="font-size: 18px;">
			<a href="https://www.hedsrl.it/"><small>Web site</small> </a> &nbsp;|
		</p>
		<p class="text-muted" style="font-size: 18px;">
			<a href="#"><small>Info</small></a> &nbsp;
		</p>
	</div>
			
</footer>-->

<footer class="bg-dark text-white mt-4">
	<div class="container-fluid py-3">
	  <div class="row">
		<div class="col-md-3">
		  <h5>BibliO.S. By H&D S.r.l.</h5></div>
		<div class="col-md-3"></div>
		<div class="col-md-3"></div>
		<div class="col-md-3"></div>
	  </div>
	  <div class="row">
		<div class="col-md-6">
			<span class="small">BibliO.S. is your bibliography research (“Bibli”) tool for Oxidative Stress (“O.S.”) and its consequences in all medical fields.<br> 
				You can find over {{contArt}} documents among clinical studies, reviews, scientific articles, conference proceedings, thesis and other materials.</span>
		</div>
		<div class="col-md-3"></div>
		<div class="col-md-3 text-right small align-self-end">
			<a href="https://www.hedsrl.it/">&copy; 2025 by H&D S.r.l.</a>
		</div>
	  </div>
	</div>
  </footer>


